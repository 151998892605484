import axios from "axios";
import { Preferences } from '@capacitor/preferences';

const state = () => ({
  authData: {
    token: "",
    refreshToken: "",
    tokenExp: "",
    userId: "",
    userName: "",
    lastError: "",
    registration: "",
    guestRegistration: "",
  },
});
  
const getters = {
    authData(state){
        return state;
    },
};
  
const actions = {
    async userLogin({commit}, user){
        var response = "";
        response = await axios.get("https://rest.bornkart.dk/api/v1/login", {
            auth: {
                username : user["name"],
                password : user["password"]
            }
        }).catch( response => response);

        if ( typeof(response.response) === "undefined" ) { 
            //  Ikke fejl retur kode
            await Preferences.set({
                key: 'userName',
                value: JSON.stringify(user["name"])
                });
            await Preferences.set({
                key: 'token',
                value: JSON.stringify(response.data.token)
                });
            commit('saveUserLogin',response); 
        } else {
            commit('saveUserLogin',response.response); 
        }
    },
    async clearUser({commit}) {
        await Preferences.clear({ key: 'token' });
        await Preferences.clear({ key: 'userName' });
        commit('clearUserAuth');
    },
    async initAuth({commit}){
        var auth = {};
        await Preferences.get({ key: 'token' }).then( data=> { auth["token"]=JSON.parse(data.value);});
        // console.log('Token = ',auth["token"]);
        await Preferences.get({ key: 'userName' }).then( data=> { auth["userName"]=JSON.parse(data.value);});
        // console.log('User = ',auth["userName"]);
        
        var response = await axios.get("https://rest.bornkart.dk/api/v1/validate_token/", {
            auth: {
                username : auth["userName"],
                password : auth["token"]
            }
        }).catch( (err) =>  { auth["userName"] = ""; auth["token"] = ""; 
        commit('initAuthState', auth); console.log(err); });
        if ( typeof response !== "undefined" && response.status === 200 ) {
            await commit('initAuthRegistration', response);
            await commit('initAuthState', auth);
        }
        
        
        response = await axios.get("https://rest.bornkart.dk/api/v1/check_source"
        ).catch( (err) =>  { console.log(err);});
        
        await commit('initGuestRegistration', response);

        return true;
    },
};
  
const mutations = {
    initAuthState(state, auth){
        // console.log('Token = ',auth["token"]);
        if ( typeof(auth["token"]) === "undefined" || auth["token"] === null || auth["token"] === "" ) {
            state["token"]="";
        } else {
            state["token"]=auth["token"];
        }
        if ( typeof(auth["userName"]) === "undefined") {
            state["userName"]="";
        } else {
            state["userName"]=auth["userName"];
        }
    },
    initGuestRegistration(state, payload) {
        if ( payload.status === 200 ) {
            state["guestRegistration"] = payload.data[0].registration;
        }
    },
    initAuthRegistration(state, payload) {
        for ( var id of Object.keys(payload.data)) { 
            var k = Object.keys(payload.data[id]);
            if ( k[0] === 'registration') {
                state["registration"] = Object.values(payload.data[id])[0];
            }
        }
        // console.log("registration = ",state["registration"]);
    },
    saveUserLogin(state, payload){
        if ( payload.status === 200 ) {
            state["userName"] = payload.config.auth.username;
            state["token"] = payload.data.token;
            state["registration"] = payload.data.registration;
            state["lastError"] = "";
        } else {
            state["token"] = "";
            state["lastError"] = payload.data;
        }
    },
    clearUserAuth(state){
            state["userName"] = "";
            state["token"] = "";
            state["lastError"] = "";
    },

};
  
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
