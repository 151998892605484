import { createRouter, createWebHistory } from '@ionic/vue-router';
import TabsPage from '../pages/TabsPage.vue'

const routes = [
  {
    path: '/',
    redirect: '/tabs/frontpage'
  },
  {
    path: '/tabs/',
    component: TabsPage,
    children: [
      {
        path: '',
        redirect: '/tabs/frontpage'
      },
      {
        path: 'frontpage',
        component: () => import('@/pages/FrontPage.vue')
      },
      {
        path: 'info',
        component: () => import('@/pages/InfoPage.vue')
      },
      {
        path: 'ranks',
        component: () => import('@/pages/RanksPage.vue')
      },
      {
        path: 'person',
        component: () => import('@/pages/PersonPage.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
