import infoModule from './modules/info';
import authModule from './modules/auth';

import {createStore} from 'vuex';
 
const store = createStore({
    modules:{
        infos: infoModule,
        auth: authModule
    }
});
 
export default store;
