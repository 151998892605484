import axios from "axios";

// const utf8 = require('utf8');

const state = () => ({
    infos:[],
    opens:[],
    champs:[],
    ranks:[],
    results:[],
    totalResults:[],
    lastResults:[],
    firstResults:[],
    showResults:[],
    registrationFields:[],
    registrationRanks:[],
    registrationChamps:[],
});
 
const getters = {
    allInfos(state){
        return state.infos;
    },
    allOpens(state){
        return state.opens;
    },
    allChamps(state){
        return state.champs;
    },
    allRanks(state){
        return state.ranks;
    },
    myResults(state){
        return state.results;
    },
    myResultsCount(state){
        return state.totalResults;
    },
    myResultsLast(state){
        return state.lastResults;
    },
    myResultsFirst(state){
        return state.firstResults;
    },
    showResults(state){
        return state.showResults;
    },
    getRegistrationFields(state){
        return state.registrationFields;
    },
    getRegistrationRanks(state){
        return state.registrationRanks;
    },
    getRegistrationChamps(state){
        return state.registrationChamps;
    },
};
 
const actions = {
    async fetchInfos({commit}){
        var response =  await axios.get("https://rest.bornkart.dk/api/v1/info");
        commit('saveAllInfos', response.data);
    },
    async fetchOpens({commit}){
        var response =  await axios.get("https://rest.bornkart.dk/api/v1/opening");
        commit('saveAllOpens', response.data);
    },
    async fetchChamps({commit}){
        var response =  await axios.get("https://rest.bornkart.dk/api/v1/mesterskaber");
        commit('saveAllChamps', response.data);
    },
    async fetchRanks({commit}, champ){
        var response =  await axios.get("https://rest.bornkart.dk/api/v1/ranglister/" + champ );
        commit('saveAllRanks', response.data);
    },
    async fetchRegistrationValues({commit}, request){
        var response =  await axios.get("https://rest.bornkart.dk/api/v1/member_registration_values", {
            auth: {
                username : request["userName"],
                password : request["token"]
            }
        });
        commit('saveAllRegistrationValues', response.data);
    },
    async fetchGuestRegistrationValues({commit}){
        var response =  await axios.get("https://rest.bornkart.dk/api/v1/guest_registration_values" );
        commit('saveAllRegistrationValues', response.data);
    },
    async fetchResults({commit}, request){
        var response =  await axios.get("https://rest.bornkart.dk/api/v1/myresults/" + request["order"] + "/" + request["last"], {
            auth: {
                username : request["userName"],
                password : request["token"]
            }
        } );
        commit('saveAllResults', response.data);
    },

};
 
const mutations = {
    saveAllInfos(state, payload){
        state.infos=[];
        for ( var tekst of payload) { state.infos.push(tekst);}
    },
    saveAllOpens(state, payload){
        state.opens=[];
        for ( var id of Object.keys(payload)) { 
            var open = JSON.stringify(payload[id]);
            var openj = JSON.parse(open);
            state.opens[JSON.stringify(Object.keys(openj)[0]).replaceAll('"','')] = JSON.stringify(Object.values(openj)[0]).replaceAll('"','');
        }
    },
    saveAllChamps(state, payload){
        state.champs=[];
        // console.log(payload);
        state.champs=payload;
        state.ranks=[];
    },
    saveAllRanks(state, payload){
        state.ranks=[];
        // console.log("ranks ", payload);
        state.ranks=payload;
    },    
    saveAllResults(state, payload){
        state.results=[];
        state.results=payload.results
        state.lastResults=payload.last.last
        state.firstResults=payload.first.first
        state.totalResults=payload.count.count
        state.showResults='ready';
    },
    saveAllRegistrationValues(state, payload){
        state.registrationFields=[];
        state.registrationRanks=[];
        state.registrationChamps=[];

        state.registrationFields=payload.fields;
        state.registrationRanks=payload.ranks;
        state.registrationChamps=payload.champs;
        state.registrationChamps['count']=payload.champs.length;
    },
};
 
export default{
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}