<template>
  <ion-page>
    <ion-tabs>
      <ion-router-outlet></ion-router-outlet>
      <ion-tab-bar slot="bottom">
        <ion-tab-button tab="info" href="/tabs/info">
          <ion-icon :icon="information" />
          <ion-label>Informationer</ion-label>
        </ion-tab-button>
          
        <ion-tab-button tab="ranks" href="/tabs/ranks">
          <ion-icon :icon="trophy" />
          <ion-label>Ranglister</ion-label>
        </ion-tab-button>
        
        <ion-tab-button tab="person" href="/tabs/person">
          <ion-icon :icon="person" />
          <ion-label>Min side</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script>
import { defineComponent } from 'vue';
import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonPage, IonRouterOutlet } from '@ionic/vue';
import { information, trophy, person } from 'ionicons/icons';

export default defineComponent({
  name: 'TabsPage',
  components: { IonLabel, IonTabs, IonTabBar, IonTabButton, IonIcon, IonPage, IonRouterOutlet },
  setup() {
    return {
      trophy, 
      person, 
      information,
    }
  }
});
</script>
